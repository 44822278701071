<template>
<div style="max-width: 350px">
    <v-row class="ma-0" align="center" :justify="justify" >
        <!-- <span style="font-size: 14px" class="mr-2">Wiedergabegeschwindigkeit:</span> -->
        <div v-on:click="$emit('update', .1)" :style="speed == .1 ? '' : 'color: rgba(0, 0, 0, .5); background-color: #E0E0E0!important; border: 1px solid #C0C0C0'" :class="speed == .1 ? 'primary ' : 'grey lighten-1'" class="ml-1 mr-2 button-container">
            <div class="button-container-text">0.25x</div>
        </div>

        <div v-on:click="$emit('update', .25)" :style="speed == .25 ? '' : 'color: rgba(0, 0, 0, .5); background-color: #E0E0E0!important; border: 1px solid #C0C0C0'" :class="speed == .25 ? 'primary' : 'grey lighten-1'" class=" mr-2 button-container">
            <div class="button-container-text">0.5x</div>
        </div>
        <div v-on:click="$emit('update', .7)" :style="speed == .7 ? '' : 'color: rgba(0, 0, 0, .5); background-color: #E0E0E0!important; border: 1px solid #C0C0C0'" :class="speed == .7 ? 'primary' : 'grey lighten-1'" class=" mr-2 button-container">
            <div class="button-container-text">1.0x</div>
        </div>

        <div v-on:click="$emit('update', 1.25)" :style="speed == 1.25 ? '' : 'color: rgba(0, 0, 0, .5); background-color: #E0E0E0!important; border: 1px solid #C0C0C0'" :class="speed == 1.25 ? 'primary' : 'grey lighten-1'" class=" mr-2 button-container">
            <div class="button-container-text">1.5x</div>
        </div>

        <div v-on:click="$emit('update', 1.5)" :style="speed == 1.5 ? '' : 'color: rgba(0, 0, 0, .5); background-color: #E0E0E0!important; border: 1px solid #C0C0C0'" :class="speed == 1.5 ? 'primary' : 'grey lighten-1'" class=" mr-1 button-container">
            <div class="button-container-text">2x</div>
        </div>
    </v-row>
    </div>
</template>

<script>

    export default {
        props: {
            speed: {
                type: Number, 
                default: .7
            }, 

            // justification of playback bar
            justify: {
                type: String,
                default: 'end'
            }
        }
    }

</script>


<style scoped>

  .button-container {
    position: relative; height: 22px; cursor: pointer; color: rgba(220, 220, 220, 1); width: 60px; background-color: rgba(150, 150, 150, 1); border-radius: 13px;
  }
  .button-container-text {
    font-size: 14px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
  }

</style>