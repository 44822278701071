<template>
  <DialogTemplate
    :actions="false"
    :padding="false"
    :title="timelapse.name"
    v-on:close="$emit('close')"
  >
    <template v-slot:content>
      <div class="ma-0" style="position: relative; height: 100%; width: 100%;">
        <div
          style="position: relative; width: 100%; overflow: hidden !important"
        >
          <div class="ml-4 mr-4" style="position: relative">
            <video
              v-on:canplaythrough="loaded = true"
              controls
              v-on:loadstart="loaded = false"
              ref="video"
              autoplay
              preload
              class="video-player"
              v-show="timelapse != null"
              muted
            >
              <source
                :src="`${timelapse.timelapse.downloadUrl}`"
                type="video/mp4"
                codecs="avc1.4d002a, mp4a.40.2"
              />
            </video>
            <v-row
              v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"
              class="ma-0 mr-1"
              align="center"
            >
              <span
                style="font-size: 14px"
                v-if="
                  timelapse.createdAt != null && timelapse.createdBy != null
                "
              >
                {{ $t('tools.project.timelapse.dialog.overview.createdAt') }}:
                <b class="ml-1" style="font-size: 14px">{{
                  $helper.formatDate(timelapse.createdAt, true, true)
                }}</b>
              </span>

              <v-spacer />
              <v-icon
                color="black"
                class="mr-1"
                style="margin-top: 2px"
                size="17"
                >mdi-calendar-range
              </v-icon>
              <span
                style="font-size: 14px"
                class="font-weight-normal black--text"
                >{{
                  $helper.formatDateRange(
                    (new Date(new Date(timelapse.startTime).setDate(new Date(timelapse.startTime).getDate() + 1)).toISOString()),
                    timelapse.endTime,
                  )
                }}</span
              >
            </v-row>
            <div v-else class="pb-5">
              <v-icon color="black" class="mr-1" size="17"
                >mdi-calendar-range</v-icon
              >
              <span
                style="font-size: 14px"
                class="font-weight-normal black--text"
                >{{
                  $helper.formatDateRange(
                    (new Date(new Date(timelapse.startTime).setDate(new Date(timelapse.startTime).getDate() + 1)).toISOString()),
                    timelapse.endTime,
                  )
                }}</span
              >
            </div>
          </div>

          <div style="background-color: white">
            <v-row
              v-show="
                !($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)
              "
              class="ma-0 pb-12 pt-6"
              style="height: 54px"
              align="center"
              justify="start"
            >
              <PlaybackSpeed
                justfiy="start"
                class="ma-0 mt-0 ml-0 ml-4"
                :speed="playbackSpeedLocal"
                v-on:update="playbackSpeedLocal = $event"
              />

              <v-spacer />

              <IconButton
                v-on:click="shareTimelapseEvent"
                color="black"
                class="mr-1"
                icon="mdi-link-variant"
              />

              <IconButton
                :disabled="!canWrite"
                v-on:click="deleteTimelapseDialog = !deleteTimelapseDialog"
                color="black"
                class="mr-4"
                icon="mdi-delete"
              />
            </v-row>
          </div>
        </div>

        <v-dialog v-model="shareObj.status" width="500">
          <ShareDialog
            v-on:close="shareObj.status = false"
            :shareObj="shareObj.data"
          />
        </v-dialog>

        <v-dialog
          v-model="deleteTimelapseDialog"
          :persistent="loadingRemoval"
          width="400"
        >
          <DialogTemplate
            v-on:close="deleteTimelapseDialog = false"
            icon="mdi-delete"
            :title="$t('tools.project.timelapse.dialog.overview.deleteTitle')"
          >
            <template v-slot:content>
              <div style="font-size: 15px" class="mb-3">
                {{ $t('tools.project.timelapse.dialog.overview.deleteText') }}
              </div>
            </template>
            <template v-slot:actions>
              <v-row class="ma-0" align="center" justify="end">
                <TextButton
                  dense
                  :loading="loadingRemoval"
                  buttonColor="primary"
                  :text="$t('helper.buttons.delete')"
                  v-on:click="deleteTimelapseEvent"
                />
              </v-row>
            </template>
          </DialogTemplate>
        </v-dialog>
      </div>
    </template>
  </DialogTemplate>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
import IconButton from '@components/button/IconButton.vue';
import ShareDialog from '@/components/tools/project/helper/ShareDialog.vue';
import TextButton from '@components/button/TextButton.vue';
import PlaybackSpeed from '@components/video/PlaybackSpeed.vue';

export default {
  name: 'TimelapseOverview',
  props: {
    timelapse: {
      type: Object,
      required: true,
    },
  },
  components: {
    DialogTemplate,
    IconButton,
    ShareDialog,
    TextButton,
    PlaybackSpeed,
  },

  data() {
    return {
      loaded: false,
      shareObj: {status: false, data: null},
      playbackSpeedLocal: 0.7,
      deleteTimelapseDialog: false,
      loadingRemoval: false,
    };
  },

  watch: {
    playbackSpeedLocal: {
      handler() {
        if (this.$refs.video == null) return;
        this.$refs.video.playbackRate = this.playbackSpeedLocal;
      },
      immediate: true,
    },
  },

  computed: {
    canWrite() {
      return this.$auth.canWrite();
    },
    author() {
      if (
        this.$store.getters.projectUsers == null ||
        this.timelapse.createdBy == null
      )
        return;
      var userIndex = this.$store.getters.projectUsers.findIndex(
        (user) => user.userId == this.timelapse.createdBy,
      );
      if (userIndex == -1) return 'AlinoCam';
      else
        return (
          this.$store.getters.projectUsers[userIndex].meta.name || 'AlinoCam'
        );
    },
  },

  methods: {
    async deleteTimelapseEvent() {
      this.loadingRemoval = true;

      const resp = await this.$api.project.removeTimelapse(
        this.timelapse.projectId,
        this.timelapse.id,
      );

      if (resp == null || resp.success == false) {
        this.$toast.error(
          this.$t('tools.project.timelapse.toasts.errorDelete'),
        );
      } else {
        this.deleteTimelapseDialog = false;
        this.$emit('delete', this.timelapse.id);
        this.$toast.info(this.$t('tools.project.timelapse.toasts.deleted'));

        this.$nextTick(() => {
          this.$emit('close');
        });
      }

      this.loadingRemoval = false;
    },
    shareTimelapseEvent() {
      this.shareObj.data = {
        shareType: 'timelapse',
        name: this.timelapse.name,
        shareUrl: `link=${encodeURIComponent(
          this.timelapse.timelapse.downloadUrl,
        )}&expiresAt=${new Date(
          this.timelapse.timelapse.expiresAt,
        ).toISOString()}`,
        presetId: this.timelapse.presetId,
        dateA: this.timelapse.startTime,
        dateB: this.timelapse.endTime,
        type: 2,
        deviceId: this.timelapse.cameraId,
        timelapseUrl: this.timelapse.timelapse.downloadUrl,
        imageUrl: this.timelapse.firstImage.downloadUrl,
        thumbnailUrl: this.timelapse.firstImageThumbnail.downloadUrl,
      };

      this.shareObj.status = true;
    },
  },
};
</script>

<style scoped>
.video-player {
  width: 100%;

  aspect-ratio: 16/9;
  border-radius: 10px !important;
}

.button-container {
  position: relative;
  height: 22px;
  cursor: pointer;
  color: rgba(220, 220, 220, 1);
  width: 60px;
  background-color: rgba(150, 150, 150, 1);
  border-radius: 13px;
}

.button-container-text {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
