<template>
  <Dialog
    :title="$t('tools.project.timelapse.dialog.createTimelapse.title')"
    v-on:closed="closeDialogEvent"
    :closeable="!loadingTimelapse"
    :close="closeTrigger"
    width="600"
  >
    <template v-slot:button="{on, attrs}">
      <span v-on="on" v-bind="attrs">
        <IconButton
          icon="mdi-plus"
          tooltipPosition="l"
          :tooltip="$t('tools.project.timelapse.dialog.createTimelapse.title')"
          style="border-radius: 50%; border: 1px solid #c0c0c0"
        />
      </span>
    </template>



    <template v-slot:content>
        
      <TextInput
        v-on:change="timelapseName = $event"
        class="mb-2 mt-2"
        :value="timelapseName"
        :placeholder="
          $t('tools.project.timelapse.dialog.createTimelapse.inputs.name')
        "
      />
      <!-- <v-divider class="ml-2 mr-2 mt-5 mb-5"/> -->

      <v-row class="ma-0 mb-3" align="center">
        <TableListRowEditable
          :noKey="true"
          :noHover="true"
          controls
          :listener="!loadingDates"
          :loading="loadingDates"
          v-on:save="updateTimelapseDateEvent"
          v-on:open="editDateRange = dateRange"
          :slotValue="true"
          :disabled="presetIndex == null"
          descr=""
          style="width: 442px !important"
          :value="1"
        >
          <template v-slot:value>
            <div
              style="
                max-width: 95%;
                position: relative;
                display: flex;
                white-space: nowrap;
              "
            >
              <!-- <v-row class="ma-0 font-weight-normal" align="center" style="width: 310px; font-weight: 300!important"> -->
              <TextInput
                :disabled="
                  presetIndex == null ||
                  loadingDates == true ||
                  dateMeta == null
                "
                style="
                  display: inline-block;
                  min-width: 160px;
                  font-weight: 500;
                  pointer-events: none;
                  user-select: none;
                "
                :value="
                  dateRange != null
                    ? $helper.formatDate(dateRange.start, false, false)
                    : ''
                "
                :placeholder="
                  $t(
                    'tools.project.timelapse.dialog.createTimelapse.inputs.startDate',
                  )
                "
              >
              </TextInput>
              <div class="ml-2 mr-2 pt-3 grey--text">-</div>
              <TextInput
                class="mr-5"
                :disabled="
                  presetIndex == null ||
                  loadingDates == true ||
                  dateMeta == null
                "
                style="
                  display: inline-block;
                  min-width: 160px;
                  font-weight: 500;
                  pointer-events: none;
                  user-select: none;
                "
                :value="
                  dateRange != null
                    ? $helper.formatDate(dateRange.end, false, false)
                    : ''
                "
                :placeholder="
                  $t(
                    'tools.project.timelapse.dialog.createTimelapse.inputs.endDate',
                  )
                "
              >
              </TextInput>
              <!-- </v-row> -->
            </div>
          </template>
          <template v-slot:input>
            <v-row class="ma-0 mt-2" align="start">
              <div>
                <vc-date-picker
                  :key="updateElement"
                  id="date-picker"
                  ref="calendar"
                  :min-date="dateMeta.min"
                  :max-date="dateMeta.max"
                  color="gray"
                  title-position="left"
                  v-model="editDateRange"
                  is-range
                />
              </div>
              <v-divider class="mr-4 ml-5 mb-2 mt-2" vertical />
              <div style="max-width: 200px" class="mt-2 pr-0">
                <TextButton
                  class="mb-3"
                  v-on:click="selectLastSevenDays()"
                  block
                  dense
                  :text="$t('helper.text.last.sevendays')"
                />
                <TextButton
                  class="mb-3"
                  v-on:click="selectLastWeek()"
                  block
                  dense
                  :text="$t('helper.text.last.week')"
                />
                <TextButton
                  class="mb-3"
                  v-on:click="selectLastMonth()"
                  block
                  dense
                  :text="$t('helper.text.last.month')"
                />
              </div>
              
            </v-row>

    
          </template>
        </TableListRowEditable>
        
        <v-spacer />
        <TextInput
          v-show="false"
          placeholder="0"
          v-on:change="timelapseFPS = $event"
          :value="timelapseFPS"
          :numberBounds="{min: 0, max: 500}"
          style="max-width: 125px; margin-top: 4px"
          number
          suffix="FPS"
        />
      </v-row>

      <div>

        <div class="mb-4 ml-2"><b class="primary--text">Hinweis:</b> Die Erstellung von Zeitraffern kann sich, je nach Länge, bis zu mehreren Stunden hinziehen. Sie können die Seite schließen und zu einem späteren Zeitpunkt prüfen, ob der Zeitraffer generiert wurde.</div>
        <div class="pb-2 pt-0">
          <div v-if="presetIndex != null && presetIndex >= 0">
            <span class="ml-2"
              >{{
                $t(
                  'tools.project.timelapse.dialog.createTimelapse.inputs.chosenPreset',
                )
              }}:
            </span>
            <b class="ml-1">{{
              $helper.formatPresetName(latestImages.data[presetIndex].presetId)
            }}</b>
          </div>
          <div v-else>
            <span
              class="ml-2"
              style="font-size: 14px; color: rgba(0, 0, 0, 0.5)"
            >
              {{
                $t(
                  'tools.project.timelapse.dialog.createTimelapse.inputs.choosePreset',
                )
              }}
            </span>
          </div>
        </div>
        <!-- <v-calendar></v-calendar> -->

        <v-divider
          v-show="latestImages.data != null && latestImages.data.length > 12"
        />
        <div
          class="pb-2 pt-1"
          style="
            width: 100%;
            margin-left: 2px;
            max-height: 285px;
            overflow: scroll;
          "
        >
          <div
            class="ma-1 pb-0"
            v-for="(view, index) in latestImages.data"
            :key="index"
            v-on:click="presetIndex = index"
            :style="
              index == presetIndex ? 'cursor: default' : 'cursor: pointer'
            "
            style="
              position: relative;
              background-color: grey;
              height: 74px;
              width: 132px;
              border-radius: 5px;
              display: inline-block;
            "
          >
            <v-img
              preload
              contain
              :src="view.thumbnail.downloadUrl"
              style="
                background-color: rgba(220, 220, 220, 1);
                position: absolute;
                border-radius: 5px;
              "
            >
              <v-overlay
                absolute
                v-show="presetIndex == index"
                :opacity="0.4"
                color="primary"
              />
            </v-img>

            <div
              :style="
                index == presetIndex
                  ? 'border: 3px solid var(--v-primary-base);'
                  : ''
              "
              style="
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: transparent;
                height: 74px;
                width: 132px;
                border-radius: 5px;
              "
            />
          </div>
        </div>

        <v-divider
          v-show="latestImages.data != null && latestImages.data.length > 12"
          class="mb-2"
        />
      </div>
    </template>
    <template v-slot:actions>
      <v-row class="ma-0 mt-2" align="center" justify="end">
        <TextButton
          class="ml-2"
          :disabled="
            timelapseName == null ||
            timelapseName.length == 0 ||
            presetIndex == null ||
            dateRange == null
          "
          :loading="loadingTimelapse"
          v-on:click="createTimelapse"
          buttonColor="primary"
          :text="
            $t('tools.project.timelapse.dialog.createTimelapse.buttons.create')
          "
        />
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@components/dialog/Dialog.vue';
import TextInput from '@components/text/TextInput.vue';
import TextButton from '@components/button/TextButton.vue';
import TableListRowEditable from '@components/table/TableListRowEditable.vue';
import IconButton from '../../../../../../ui-components/src/components/button/IconButton.vue';
export default {
  name: 'CreateTimelapse',
  components: {
    Dialog,
    TextButton,
    TextInput,
    TableListRowEditable,
    IconButton,
  },
  data() {
    return {
      updateElement: false,
      loadingTimelapse: false,
      loadingDates: false,
      closeTrigger: false,
      presetIndex: null,
      editDateRange: null,
      dateRange: null,
      timelapseFPS: 30,
      timelapseName: '',
      dateMeta: null,
    };
  },

  watch: {
    presetIndex: {
      handler() {
        this.dateRange = null;
        if (this.presetIndex != null) this.getPresetDates();
      },
      immediate: true,
    },
  },
  computed: {
    latestImages() {
      return this.$store.getters.latest;
    },
  },

  methods: {
    async createTimelapse() {
      this.loadingTimelapse = true;
      const presetObj = this.latestImages.data[this.presetIndex];
      if (presetObj == null) return;

      var resp = await this.$api.project.createTimelapse(
        this.$route.params.projectId,
        presetObj.cameraId,
        presetObj.presetId,
        {
          name: this.timelapseName,
          startTime: new Date(new Date(this.editDateRange.start).setDate(new Date(this.editDateRange.start).getDate()-1)).toISOString(),
          endTime: new Date(this.editDateRange.end).toISOString(),
        },
      );

      if (resp.success == true) {
        this.$emit('created', resp.data);
        this.$toast.info(
          this.$t(
            'tools.project.timelapse.dialog.createTimelapse.toasts.creatingTimelapse',
          ),
        );
        this.closeDialog();
      } else {
        this.$toast.error(
          this.$t(
            'tools.project.timelapse.dialog.createTimelapse.toasts.errorCreating',
          ),
        );
        this.loadingTimelapse = false;
      }
    },
    async getPresetDates() {
      this.loadingDates = true;

      var resp;
      try {
        resp = await this.$api.project.presetDates(
          this.latestImages.data[this.presetIndex].cameraId,
          this.$route.params.projectId,
          this.latestImages.data[this.presetIndex].presetId,
        );
      } catch {
        return;
      }

      if (resp == null) return;

      var dates = resp
        .map((date) => date.date)
        .sort((a, b) => Date.parse(b) - Date.parse(a));
      this.dateMeta = {
        min: Math.min(...dates),
        max: Math.max(...dates),
        allowed: dates,
        data: resp,
      };

      this.loadingDates = false;
    },
    closeDialog() {
      this.loadingTimelapse = false;
      this.closeTrigger = true;

      this.$nextTick(() => {
        this.closeTrigger = false;
      });
    },
    updateTimelapseDateEvent() {
      this.updateElement = !this.updateElement;
      this.dateRange = this.editDateRange;
    },

    closeDialogEvent() {
      document.getElementById('create-button').blur();
      setTimeout(() => {
        this.loadingTimelapse = false;
        this.loadingDates = false;
        this.presetIndex = null;
        this.editDateRange = null;
        this.dateRange = null;
        (this.timelapseFPS = 30),
          (this.timelapseName = ''),
          (this.dateMeta = null);
      }, 250);
    },
    getParticularDayTimestamp(lastWeekDay) {
      var currentWeekMonday = new Date().getDate() - new Date().getDay() + 1;
      return new Date().setDate(currentWeekMonday - lastWeekDay);
    },
    selectLastSevenDays() {
      var dateHandle = {
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
        end: new Date(),
      };

      if (dateHandle.start < this.dateMeta.min)
        dateHandle.start = this.dateMeta.min;
      if (dateHandle.end > this.dateMeta.max)
        dateHandle.end = this.dateMeta.max;

      this.editDateRange = dateHandle;
    },
    selectLastWeek() {
      var dateHandle = {
        start: new Date(this.getParticularDayTimestamp(7)),
        end: new Date(this.getParticularDayTimestamp(1)),
      };

      if (dateHandle.start < this.dateMeta.min)
        dateHandle.start = this.dateMeta.min;
      if (dateHandle.end > this.dateMeta.max)
        dateHandle.end = this.dateMeta.max;

      this.editDateRange = dateHandle;
    },
    selectLastMonth() {
      var date = new Date();
      var dateHandle = {
        start: new Date(date.getFullYear(), date.getMonth(), 1),
        end: new Date(),
      };

      if (dateHandle.start < this.dateMeta.min)
        dateHandle.start = this.dateMeta.min;
      if (dateHandle.end > this.dateMeta.max)
        dateHandle.end = this.dateMeta.max;

      this.editDateRange = dateHandle;
    },
    selectLastYear() {
      var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);

      var dateHandle = {
        start: new Date(lastyear.getFullYear(), 0, 1).getTime(),
        end: new Date(lastyear.getFullYear(), 11, 31).getTime(),
      };

      if (dateHandle.start < this.dateMeta.min)
        dateHandle.start = this.dateMeta.min;
      if (dateHandle.end > this.dateMeta.max)
        dateHandle.end = this.dateMeta.max;

      this.editDateRange = dateHandle;
    },
  },
};
</script>

<style>
.vc-title {
  pointer-events: none !important;
}

.vc-container {
  border: none !important;
  font-family: 'Lato', sans-serif !important;
}

.vc-arrow {
  border-radius: 50%;
}
</style>
