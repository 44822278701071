<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :hideHeader="false"
    :title="$t(`${$route.meta.i18n}.title`)"
    :error="isFailed"
    :searchable="false"
    :loading="isLoading"
    :tabs="true"
  >
    <template v-slot:header-action>
      <v-row class="ma-0" align="center">
        <CreateTimelapse
          v-show="canWrite"
          v-on:created="createdTimelapseEvent"
          class="mr-1"
        />
        <TextInput
          clearable
          :disabled="timelapseData == null || timelapseData.length == 0"
          icon="mdi-magnify"
          :value="searchTimelapse"
          v-on:change="searchTimelapse = $event"
          class="ml-2"
          :placeholder="`${$t('helper.text.search')}...`"
          style="width: 300px"
        />
        <IconButton
          :disabled="
            timelapseFilterList == null || timelapseFilterList.length == 0
          "
          class="ml-3 mr-1"
          v-on:click="sortDescending = !sortDescending"
          :icon="
            sortDescending
              ? 'mdi-sort-alphabetical-descending'
              : 'mdi-sort-alphabetical-ascending'
          "
        />
      </v-row>
    </template>

    <template v-slot:content>
      <div
        v-if="timelapseFilterList != null && timelapseFilterList.length > 0"
        class="timelapse-gallery pa-2"
      >
        <Gallery
          :dense="true"
          :noOverlay="true"
          v-on:hover-click="openTimelapsePreview"
          id="gallery-latest"
          class=""
          :data="timelapseFilterList"
        >
          <template v-slot:content="{data, hover}">
            <TimelapseGalleryCard
              v-on:delete="deletedTimelapseEvent"
              :data="data"
              :hover="hover"
            />
          </template>
        </Gallery>
      </div>
      <div v-else>
        <v-row
          class="ml-5 mt-3 grey--text"
          align="center"
          justify="center"
          style="font-size: 14px"
        >
          {{ $t('tools.project.timelapse.noDataFound') }}</v-row
        >
      </div>
      <v-dialog
        v-show="timelapsePreview.data != null"
        @click:outside="closePreviewDialog"
        width="800"
        :persistent="loadingDownload"
        v-if="timelapsePreview.data != null"
        v-model="timelapsePreview.status"
      >
        <TimelapseOverview
          v-on:delete="deletedTimelapseEvent"
          :key="timelapsePreview.status"
          v-on:close="closePreviewDialog"
          :timelapse="timelapsePreview.data"
        />
      </v-dialog>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';

import CreateTimelapse from '../../../../components/tools/project/timelapse/dialog/CreateTimelapse.vue';

import Gallery from '@components/list/Gallery.vue';
import TimelapseOverview from '../../../../components/tools/project/timelapse/dialog/TimelapseOverview.vue';
import TimelapseGalleryCard from '../../../../components/tools/project/timelapse/TimelapseGalleryCard.vue';
import IconButton from '@components/button/IconButton.vue';
import TextInput from '@components/text/TextInput.vue';
export default {
  name: 'ProjectViewTimelapse',
  components: {
    Gallery,
    ProjectPageTemplate,
    CreateTimelapse,
    TimelapseOverview,
    TimelapseGalleryCard,
    IconButton,
    TextInput,
  },

  data() {
    return {
      searchTimelapse: '',
      sortDescending: false,
      refreshInterval: null,
      loadingShareLink: false,
      closeDeleteDialog: false,
      loadingDelete: false,
      loadingDownload: false,
      isLoading: true,
      isFailed: false,
      timelapseData: null,
      timelapsePreview: {
        status: false,
        data: null,
      },
    };
  },

  computed: {
    timelapseFilterList() {
      return (this.timelapseData || []).filter((timelapse) =>
        timelapse.name.includes(this.searchTimelapse),
      );
    },
    canWrite() {
      return this.$auth.canWrite();
    },

    timelapseTabs() {
      return [{name: 'Vorher', id: 'before', icon: 'mdi-dashboard'}];
    },
  },

  async created() {
    this.timelapseData = null;
    this.isLoading = true;
    await this.getTimelapseData();
    this.isLoading = false;
  },

  watch: {
    sortDescending() {
      this.timelapseData.reverse();
    },
  },

  methods: {
    deletedTimelapseEvent(id) {
      const index = this.timelapseData.findIndex(
        (timelapse) => timelapse.id == id,
      );
      if (index >= 0) this.timelapseData.splice(index, 1);
      this.getTimelapseData();
    },
    createdTimelapseEvent(eventHandle) {
      if (this.timelapseData == null || this.timelapseData.length == 0) {
        this.timelapseData = [eventHandle];
      } else if (this.sortDescending == true)
        this.timelapseData.push(eventHandle);
      else this.timelapseData.unshift(eventHandle);

      this.openTimelapsePreview(eventHandle);
      this.getTimelapseData();
    },

    async download() {
      this.loadingDownload = true;
      await this.$helper.download(
        this.timelapsePreview.data.videoUrl,
        this.timelapsePreview.data.name,
        'video',
        'mp4',
      );
      setTimeout(() => {
        this.loadingDownload = false;
      }, 1000);
    },
    closePreviewDialog() {
      if (this.loadingDownload) return;
      this.timelapsePreview.status = false;
      setTimeout(() => {
        this.timelapsePreview.data = null;
      }, 250);
    },
    openTimelapsePreview(eventHandle) {
      if (eventHandle.timelapse == null) return;
      this.timelapsePreview.data = eventHandle;
      this.timelapsePreview.status = true;
    },
    async getTimelapseData() {
      if (
        this.$store.getters.latest == null ||
        this.$store.getters.latest.data == null
      ) {
        this.isFailed = true;
        return;
      }

      if (this.refreshInterval != null) clearTimeout(this.refreshInterval);
      var resp = await this.$api.project.timelapses(
        this.$route.params.projectId,
      );

      if (resp.success == true && resp.data != null) {
        if (resp.data.timelapses == null) this.timelapseData = [];
        else {
          if (
            resp.data.timelapses.some(
              (timelapse) => timelapse.timelapse == null,
            )
          ) {
            this.refreshInterval = setTimeout(() => {
              this.getTimelapseData();
            }, 1000);
          }

          this.timelapseData = resp.data.timelapses
            .filter((tl) => tl.createdAt != null)
            .sort((a, b) => a.createdAt > b.createdAt);
          if (!this.sortDescending) {
            this.timelapseData = this.timelapseData.reverse();
          }
        }
      } else {
        if (this.timelapseData == null) {
          this.isFailed = true;
        }
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.timelapse-gallery {
    
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
