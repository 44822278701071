<template>
  <div
    style="border-radius: 5px"
    :style="
      data.timelapse == null
        ? 'cursor: default!important; opacity: .9 '
        : 'cursor: pointer!important'
    "
  >
    <div
      v-if="hover && data != null && data.timelapse != null"
      style="background-color: transparent; position: absolute; border-radius: 5px!important overflow: hidden!important; z-index: 5!important; border-radius: 5px; height: 100%; width: 100%; overflow: hidden; "
    >
      <video
        v-if="data.timelapse != null && hover == true"
        id="video-player"
        style="width: 100%; border-radius: 5px!important overflow: hidden!important;"
        v-on:loaded="loadedVideoEvent"
        v-on:canplaythrough="loadedVideoEvent"
        :style="!loaded ? 'opacity: 0; z-index: 0;' : 'z-index: 20'"
        ref="video"
        autoplay
        muted
      >
        <source
          :src="`${data.timelapse.downloadUrl}`"
          type="video/mp4"
          codecs="avc1.4d002a, mp4a.40.2"
        />
      </video>
    </div>
    <img
      style="
        position: absolute;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        overflow: hidden;
      "
      :src="data.firstImageThumbnail.downloadUrl"
    />

    <div
      v-show="data.timelapse == null"
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
      "
    >
      <div
        style="
          position: absolute;
          top: calc(50% + 10px);
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <v-col class="ma-0" align="center">
          <v-progress-circular
            indeterminate
            width="3"
            size="30"
            color="primary"
          ></v-progress-circular>
        </v-col>
      </div>

      <TextButton
        v-on:click="cancelTimelapseCreation"
        style="
          opacity: 0.7;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 5px;
        "
        dense
        class="mt-4"
        text="Abbrechen"
      />
    </div>

    <!-- <div v-if="!loaded && hover && data.timelapse != null"
      style="position: absolute; bottom: 10px; right: 10px; height: 30px; width: 30px; border-radius: 15px; background-color: rgba(255, 2555, 255, .9); opacity: .6">
      <v-progress-circular indeterminate width="3" size="20" color="primary" class="center"></v-progress-circular>
    </div> -->

    <v-fade-transition>
      <v-icon
        style="
          position: absolute;
          z-index: 2;
          top: calc(50% + 5px);
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.6;
        "
        color="black"
        size="60"
        v-show="hover && data.timelapse != null"
        >mdi-play-circle</v-icon
      >
    </v-fade-transition>

    <div
      style="
        position: absolute;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        z-index: 50 !important;
        top: 0px;
        height: 35px;
        width: 100%;
        background-color: rgba(220, 220, 220, 0.9);
      "
    >
      <div class="mt-1 ml-3">
        <v-row
          class="ma-0"
          align="center"
          style="margin-top: 1px"
          justify="start"
        >
          <div
            style="
              max-width: 42%;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            "
            class="font-weight-bold"
            :class="hover && data.timelapse != null ? 'primary--text' : ''"
          >
            {{ data.name }}
          </div>
          <v-spacer />
          <span
            style="font-size: 14px; color: rgba(0, 0, 0, 0.6)"
            class="mr-3 font-weight-normal"
            >{{ $helper.formatDateRange((new Date(new Date(data.startTime).setDate(new Date(data.startTime).getDate() + 1)).toISOString()), data.endTime) }}
          </span> 
          <!-- <v-spacer/> -->
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';

export default {
  name: 'TimelapseGalleryCard',
  props: {
    data: {
      required: true,
    },
    hover: {
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  watch: {
    hover() {
      this.loaded = false;
    },
  },
  methods: {
    loadedVideoEvent() {
      var player = document.getElementById('video-player');
      if (player == null) return;
      player.addEventListener('playing', () => {
        this.loaded = true;
      });
      // if (this.$refs.video == null) return;
      // this.$refs.video.playbackRate = 2;
    },
    async cancelTimelapseCreation() {
      await this.$api.project.removeTimelapse(
        this.data.projectId,
        this.data.id,
      );
      this.$emit(this.data.id);
    },
  },
  components: {TextButton},
};
</script>

<style>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
